// If you want to override variables do it here
@import 'variables';

$enable-ltr: true;
$enable-rtl: true;

// Import CoreUI for React components library
@import '@coreui/coreui/scss/coreui';

// Import Chart.js custom tooltips styles
@import '@coreui/chartjs/scss/coreui-chartjs';

@import 'layout';
@import 'example';

// If you want to add custom CSS you can put it here.
@import 'custom';
body{
  background: #181a20;
}
.rc-pagination {
  text-align: right;
}

.add-btn {
  color: #fff !important;
  border-color: #162af1 !important;
  background-color: #162af1 !important;
  box-shadow: none !important;
  float: right;
}

.add-btn:hover {
  color: #fff !important;
  border-color: #162af1 !important;
  background-color: #162af1 !important;
}

.submit-btn {
  color: #fff !important;
  border-color: #162af1 !important;
  background-color: #162af1 !important;
  box-shadow: none !important;
  // margin-left: 85%;
  float: right;
}

.submit-btn:hover {
  color: #fff !important;
  border-color: #162af1 !important;
  background-color: #162af1 !important;
  box-shadow: none !important;
}

.back-btn {
  color: #fff !important;
  border-color: #162af1 !important;
  background-color: #162af1 !important;
  box-shadow: none !important;
  // margin-left: 85%;
  float: right;
}

.back-btn:hover {
  color: #fff !important;
  border-color: #162af1 !important;
  background-color: #162af1 !important;
  box-shadow: none !important;
}

.edit-btn {
  color: #fff !important;
  border-color: #162af1 !important;
  background-color: #162af1 !important;
  box-shadow: none !important;
}

.edit-btn:hover {
  color: #fff !important;
    background-color: #162af1 !important;
    border-color: #162af1 !important;
  box-shadow: none !important;
}

#btn {
  margin-right: '30px';
}

.dispute_detail {
  margin-left: 10px;
}

.margin_right {
  margin-right: 10px;
}

.btn {
  margin-left: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.lds-hourglass {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-hourglass:after {
  content: ' ';
  display: block;
  border-radius: 50%;
  width: 0;
  height: 0;
  margin: 8px;
  box-sizing: border-box;
  border: 32px solid rgb(36, 1, 1);
  border-color: rgba(11, 12, 12, 0.142) transparent #fff transparent;
  animation: lds-hourglass 1.2s infinite;
}

@keyframes lds-hourglass {
  0% {
    transform: rotate(0);
    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
  }

  50% {
    transform: rotate(900deg);
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
  }

  100% {
    transform: rotate(1800deg);
  }
}

.table-responsive thead th {
  white-space: nowrap;
}

.marginLeft {
  margin-left: 16px;
}

.marginRigth {
  margin-left: 20px;
}

#errorleft {
  margin-left: 180px;
}


.form-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.form-bottom a{
  color: #162af1 !important;
  text-decoration: none;
}
.bgimg {
  position: relative;
}
.title-level-3{
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #ffffff;
}
.card {
  background: transparent !important;
  border: 0;
}

.card-header {
  margin-bottom: 15px;
  color: #ffffff;
}
.wrapper{
  background: #181a20;
}
.card-body,
.card-header {
  backdrop-filter: blur(4.5px);
  background: #1e2026 !important;
  border: 1px solid #1e2026;
  border-radius: 10px !important;
  color: #fff;
}
.table th,
.table td{
  color: #ffffff;
}
.card-footer {
  border: 0;
  background: transparent;
}

.form-control::-ms-input-placeholder { /* Edge 12-18 */
  color: #8c8c8c;
}
.link-button{
  text-decoration: none !important;
  color: #ffffff !important;
}
.form-check-input:checked{
  background-color:  #162af1 !important;
  border-color: #162af1 !important;
}
.left-align-button{
  display: flex;
  justify-content: flex-start;
  margin-top: 15px;
}
.form-check-label{
  color: #ffffff !important;
}
.form-control::placeholder {
  color: #8c8c8c;
}
.header {
  background: hsla(0, 0%, 100%, .2) !important;
  border: 1px solid hsla(0, 0%, 100%, .5);
  border-radius: 10px !important;
  margin-bottom: 15px;
}

.input-group .input-group-text {
  background: #181a20 !important;
  border-color: #181a20 !important;
  border-radius: 20px 0 0 20px;
  color: #ffffff;
}
.table-responsive td a{
  color: #4151f2 !important;
  text-decoration: none;
}
.form-control {
  background: #181a20 !important;
  border-color: #181a20 !important;
  border-radius: 20px;
  box-shadow: none !important;
  color: #fff !important;
  min-height: 38px;
}

.form-control + .input-group-text{
  background: #181a20 !important;
  border-color: #181a20 !important;
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
}
.form-control + .input-group-text *{
  color: #ffffff !important;
}
.form-select {
  background-color: #181a20;
  border-color: #181a20 !important;
  border-radius: 30px;
  box-shadow: none !important;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  height: 38px;
  line-height: unset;
  padding: 5px 20px;
  text-transform: capitalize;
  width: 100% !important;
}
.rmsc .dropdown-container,
.select__control{
  background-color: #181a20 !important;
  border-color: #181a20 !important;
  border-radius: 30px !important;
  box-shadow: none !important;
  outline: none !important;
  color: #ffffff !important;
}
.DDRK{
  background: transparent !important;
}
.bgSelectColor{
  background: #1e2026 !important;
  border-color: rgb(51 53 56) !important;
  border-radius: 30px !important;
  box-shadow: none !important;
  outline: none !important;
  color: #ffffff !important;
}
.bgSelectColor span{
  background-color: rgb(39, 31, 117) !important;
}
.pagination-main {
  display: flex;
  justify-content: space-between;
}

.dropdown-menu {
  backdrop-filter: blur(4.5px);
  background: #121418;
  border: 1px solid #1e2026;
}

.form-check-label {
  margin: 0 5px;
}
.modal-body{
  color: #ffffff;
}
.modal-header{
  border-color: #1e2026 !important;
  color: #ffffff;
}
.btn-close{
  filter: invert(1);
}
.modal-content {
  background: #121418 !important;
  border: 1px solid #1e2026;
  border-radius: 10px !important;
}
.modal-footer{
  border-color: #1e2026 !important;
}
.nav-tabs {
  border: 0;
  margin-bottom: 15px;
  gap: 10px;
}

.nav-tabs .nav-link {
  background: transparent;
  border: 1px solid #162af1;
  border-radius: 0.35rem;
  color: #ffffff;
  font-weight: 600;
  min-width: 150px;
  padding: 12px 20px;
  white-space: nowrap;
}

.nav-tabs .nav-link.active {
  border-color: #162af1;
  background: #162af1;
  color: #fff;
}
.table-responsive thead th {
  color: #fff;
  font-size: 12px !important;
  white-space: nowrap;
}
.table-responsive td {
  color: #fff !important;
  font-size: 12px !important;
  font-weight: 700;
}
.table-responsive td .input-group-text{
  background: #1e2026;
  border-color: #1e2026;
  color: #162af1;
}
.table-responsive .form-select {
  font-size: 12px !important;
}
table {
  vertical-align: middle !important;
}
.breadcrumb-item.active{
  color: #ffffff !important;
}
.breadcrumb-item a{
  color: #4151f2;
  text-decoration: none;
}
.header-divider{
  border-color: #1e2026 !important;
}
thead,
tbody,
tfoot,
tr,
td,
th {
  background: transparent !important;
  border: 0;
  white-space: nowrap;
}
.breadcrumb-item + .breadcrumb-item::before{
  color: #1e2026 !important;
}
tbody tr:nth-child(odd) {
  background: rgba(36,38,45,.5) !important;
}
tbody tr:nth-child(even) {
  background: #1e2026 !important;
}

.header.header-sticky {
  background: #121418 !important;
  border: 1px solid #1e2026;
  border-radius: 0px !important;
}
.form-label{
  color: #ffffff !important;
}
.sidebar {
  background: #121418 !important;
  border: 1px solid #1e2026;
  border-radius: 0px !important;
}

.sidebar_bg {
   background: #121418 !important;
  border: 1px solid #1e2026;
  border-radius: 10px !important;
}

.sidebar-brand {
  background: transparent;
}

.sidebar-nav {
  background: transparent !important;
}

.sidebar-nav .nav-link {
  color: #fff;
  font-size: 14px;
  font-weight: 700;
}
.dropdown-item{
  color: #ffffff;
}
.sidebar-nav .nav-link.active,
.sidebar-nav .nav-link:hover,
.sidebar-nav .nav-group.show .nav-group-toggle,
.sidebar-nav .nav-group.show,
.dropdown-item:hover,
.dropdown-item:focus {
  color: #ffffff;
  background: #162af1; 

}

.sidebar-nav .nav-group-toggle::after {
  filter: invert(1);
}

.sidebar-nav .nav-group.show {
  background: rgb(0 0 0 / 8%);
}
.card-body small{
  color: #ffffff;
}
.sidebar-toggler {
  background: #0e1017;
}
.sidebar-toggler:hover {
  background:  #0e1017;
  ;
}
.rc-pagination-total-text{
  color: #ffffff !important;
}
.rc-pagination-disabled .rc-pagination-item-link, .rc-pagination-disabled:hover .rc-pagination-item-link, .rc-pagination-disabled:focus .rc-pagination-item-link {
  color: rgb(161, 156, 226) !important;
  border-color: #4151f2 !important;
  cursor: not-allowed !important;
}
.rc-pagination-disabled .rc-pagination-item-link, .rc-pagination-disabled:hover .rc-pagination-item-link, .rc-pagination-disabled:focus .rc-pagination-item-link {
  color: rgb(161, 156, 226) !important;
  cursor: not-allowed;
  background: #1e2026 !important;
}
.rc-pagination-prev .rc-pagination-item-link, .rc-pagination-next .rc-pagination-item-link{
  color: rgb(161, 156, 226) !important;
  border-color: #4151f2 !important;
}
.rc-pagination-item-active {
  font-weight: 500 !important;
  background: #1e2026 !important;
  border-color: #414652 !important;
  color: #fff !important;
}
.btn-group button {
  border-radius: var(--cui-border-radius);
  color: #fff !important;
  fill: #fff !important;
}

.dash_card .card {
   background: #121418 !important;
    border-radius: 10px !important;
  color: #000 !important;
}

.dash_card .card-body {
  border: 0;
  border-bottom: 1px solid #1e2026;
  border-radius: 10px 10px 0 0 !important;
  color: #ffffff;
}

.chartjs-tooltip tbody tr:nth-child(odd) {
  background: transparent !important;
}




.sts_wid {
  min-width: 150px;
}

.primary_btn {
  color: #fff !important;
  border-color: #162af1 !important;
  background-color: #162af1 !important;
  box-shadow: none !important;
}

.footer {
  background: #121418 !important;
  border-color: #1e2026;
  color: #ffffff !important;
}

@media only screen and (max-width: 575px) {

  .pagination-main {
    flex-direction: column;
    align-items: center;
  }

  .card-body .form-label {
    white-space: normal;
    word-break: break-all;
  }
}

.active_store {
  box-shadow: 0 0 18px 5px rgba(0, 0, 0, 0.05);
  background: #fff;
  border: 1px solid #b8fcf0;
  border-radius: 20px;
  height: 130px;
  display: flex;
  align-items: center;
  overflow: hidden;
  justify-content: center;
}
.align_li .nav-link + ul  .nav-link + ul .nav-link {
  padding-left: 4.5rem !important;
  background: #4454fa;
}


.tab_size .nav-link{
  color: #fff;
  text-align: center;
  min-width: 80px;
  padding: 7px 6px;
  font-size: 11px;
}
.tab_size{
  gap: 5px;
}
.bg_count{
  background: #1e2026;
  color: #fff;
  outline: 0 !important;
}
.rc-pagination-item,
.rc-pagination-next .rc-pagination-item-link{
  background-color: #1e2026 !important;
  border: 1px solid #414652 !important;
}
.rc-pagination-prev .rc-pagination-item-link, .rc-pagination-next .rc-pagination-item-link{
  background-color: #1e2026 !important;
  border: 1px solid #414652 !important;
}

.rmsc {
  --rmsc-main: #4285f4 !important ;
  --rmsc-hover: #181a20 !important ;
  --rmsc-selected: #181a20 !important ;
  --rmsc-border: #333333  !important; 
  --rmsc-gray: #ffffff !important ;
  --rmsc-bg: #181a20 !important ;
  color: #fff !important;
}
.rmsc input{
  color: #fff !important;
}
.table, label {
  font-size: 12px !important;
  font-weight: 700;
}
.nav-link.last-child-link {
  min-width: auto;
}
button, .btn {
  font-size: 12px !important;
}
.form-select, .form-control, .card-body {
  font-size: 12px !important;
}
.bredcrum-flex {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.rc-pagination.rc-pagination-disabled .rc-pagination-item a{
  color: rgb(255 255 255 / 41%) !important;
}